import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import BackgroundImage from '../components/BackgroundBottom';
import Term from '../components/Term';
import Navbar from '../components/Navbar';
import styled from 'styled-components';
import toTopArrow from '../img/to-top-arrow.svg';
import smoothscroll from 'smoothscroll-polyfill';

export class TermsPageTemplate extends React.Component {
  constructor(props) {
    super();
    this.page = props.page;
  }

  inputRefs = [];

  setRef = (ref) => {
    this.inputRefs.push(ref);
  };

  scrollToTerm = (index) => {
    if (this.inputRefs[index]) {
      this.inputRefs[index].scrollIntoView({ behavior: 'smooth' });
    }
  };

  componentDidMount() {
    try {
      smoothscroll.polyfill();
    } catch (e) {
      console.log(e);
    }
    this.scrollToTop = () => {
      if (typeof window !== 'undefined') {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      }
    };
  }

  render() {
    return (
      <div
        style={{
          color: 'white',
          backgroundColor: '#110D11',
          borderBottom: '1px solid #251f26',
        }}
      >
        <section className="hero is-medium has-background">
          <img src="../img/fc-terms-header-img.png" alt="" className="hero-background" />
          <BackgroundImage zIndex={0} />
          <div className="hero-head">
            <div className="has-text-centered is-5-to-10-margin">
              <Navbar />
            </div>
          </div>
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-offset-4 is-5">
                  <h1 className="is-size-1" style={{ paddingBottom: '20px' }}>
                    {this.page.frontmatter.title}
                  </h1>
                  <HeaderList>
                    {this.page.frontmatter.terms.map((term, index) => (
                      <HeaderListItem key={index} onClick={() => this.scrollToTerm(index)}>
                        {term.termTitle}
                      </HeaderListItem>
                    ))}
                  </HeaderList>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container" style={{ paddingBottom: '75px' }}>
          {this.page.frontmatter.terms.map((term, index) => (
            <section key={index} id={'term-' + (index + 1)} className="section" ref={this.setRef}>
              <Term title={term.termTitle} points={term.points} index={index + 1} />
            </section>
          ))}
        </section>
        <section style={{ paddingBottom: '75px' }}>
          <div className="columns is-mobile">
            <div className="column is-offset-10-tablet is-1-tablet is-offset-9-mobile is-2-mobile">
              <Img
                src={toTopArrow}
                alt="Back to Top"
                title="Back to Top"
                onClick={() => this.scrollToTop()}
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const TermsPage = ({ data }) => {
  const { markdownRemark: page } = data;
  const {
    frontmatter: {
      seo: { browserTitle },
    },
  } = page;

  return (
    <Layout>
      <Helmet>
        <title>{browserTitle}</title>
      </Helmet>
      <TermsPageTemplate page={{ ...page, bodyIsMarkdown: false }} />
    </Layout>
  );
};

TermsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TermsPage;

export const termsPageQuery = graphql`
  query TermsPage {
    markdownRemark(frontmatter: { templateKey: { eq: "privacy-page" } }) {
      frontmatter {
        title
        terms {
          termTitle
          points {
            mainPoint
            subpoints {
              subpoint
            }
          }
        }
        seo {
          browserTitle
        }
      }
    }
  }
`;

const HeaderList = styled.ol`
  margin-inline-start: 1em;
  font-weight: bold;
`;

const HeaderListItem = styled.li`
  padding-bottom: 5px;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

const Img = styled.img`
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;
